import React, { createContext, useMemo, useState } from 'react';
import './App.css';
import Dashboard from './components/main/Dashboard';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SignUp2 from './components/auth/SignUp2';
import SignIn2 from './components/auth/SignIn2';
import { ThemeProvider, createTheme } from '@mui/material';

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [mode, setMode] = useState<'light' | 'dark'>('light');

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        console.log('PRESSED');
        setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    [],
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode],
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <Dashboard currentUser={currentUser} setCurrentUser={setCurrentUser} />
      ),
    },
    {
      path: '/login',
      element: (
        <SignIn2 currentUser={currentUser} setCurrentUser={setCurrentUser} />
      ),
    },
    {
      path: '/register',
      element: (
        <SignUp2 currentUser={currentUser} setCurrentUser={setCurrentUser} />
      ),
    },
  ]);

  return (
    <div className="App">
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </ColorModeContext.Provider>
    </div>
  );
}

export default App;
