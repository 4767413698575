import React, { createContext, useContext, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import { pink } from '@mui/material/colors';
import Toolbar from '@mui/material/Toolbar';
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase/firebase';

// icons
import PageviewIcon from '@mui/icons-material/Pageview';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { ColorModeContext } from '../../App';

const Header = ({
  currentUser,
  setCurrentUser,
}: {
  currentUser: any;
  setCurrentUser: any;
}) => {
  useEffect(() => {}, [currentUser]);
  const theme = useTheme();

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      sx={{ borderBottom: theme => `1px solid ${theme.palette.divider}` }}>
      <Toolbar sx={{ flexWrap: 'wrap' }}>
        <Avatar sx={{ bgcolor: pink[500] }}>
          <PageviewIcon />
        </Avatar>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1 }}
          align="left"
          marginLeft={'0.5em'}>
          Flinkus
        </Typography>
        {!currentUser && (
          <>
            <Button href="/register" variant="outlined" sx={{ my: 1, mx: 1.5 }}>
              Register
            </Button>
            <Button href="/login" variant="contained" sx={{ my: 1, mx: 1.5 }}>
              Login
            </Button>
          </>
        )}
        {currentUser && (
          <>
            <Button
              onClick={() => {
                signOut(auth);
                setCurrentUser(null);
              }}
              variant="outlined"
              sx={{ my: 1, mx: 1.5 }}>
              Log Out
            </Button>
            <Button href="/profile" variant="contained" sx={{ my: 1, mx: 1.5 }}>
              Profile
            </Button>
          </>
        )}
        <ColorModeContext.Consumer>
          {colorMode => (
            <>
              <IconButton
                sx={{ ml: 1 }}
                onClick={colorMode.toggleColorMode}
                color="inherit">
                {theme.palette.mode === 'dark' ? (
                  <Brightness7Icon />
                ) : (
                  <Brightness4Icon />
                )}
              </IconButton>
            </>
          )}
        </ColorModeContext.Consumer>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
